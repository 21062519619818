// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-books-book-template-js": () => import("./../../../src/components/books/book-template.js" /* webpackChunkName: "component---src-components-books-book-template-js" */),
  "component---src-pages-books-currently-reading-js": () => import("./../../../src/pages/books/currently-reading.js" /* webpackChunkName: "component---src-pages-books-currently-reading-js" */),
  "component---src-pages-books-index-js": () => import("./../../../src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-books-read-js": () => import("./../../../src/pages/books/read.js" /* webpackChunkName: "component---src-pages-books-read-js" */),
  "component---src-pages-books-to-read-js": () => import("./../../../src/pages/books/to-read.js" /* webpackChunkName: "component---src-pages-books-to-read-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

